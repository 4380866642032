import { useCallback, useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { useSelector } from 'react-redux';
import { loadCalendar } from 'core/store/calendar/calendar.actions';
import moment from 'moment';

import 'moment/locale/nl-be';

import { calendarFormats, calendarTranslation } from './calendar.service';
import CalendarDay from './CalendarDay';
import CalendarSubscribe from './CalendarSubscribe';
import CalendarToolbar from './CalendarToolbar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';

const Calendar = () => {
	const localizer = momentLocalizer(moment);
	localizer.segmentOffset = 0;

	const { calendar } = useSelector(state => state.calendarReducer);

	const [view, setView] = useState('month');
	const [date, setDate] = useState(new Date());

	const onView = useCallback(newView => setView(newView), [setView]);
	const onNavigate = useCallback(newDate => setDate(newDate), [setDate]);

	useEffect(() => {
		loadCalendar(date, view);
	}, [date, view]);

	return (
		<div className='calendar__wrapper'>
			<BigCalendar
				views={{
					month: true,
					week: true,
					work_week: true,
					day: true,
					agenda: true,
					subscribe: CalendarSubscribe,
				}}
				onView={onView}
				onNavigate={onNavigate}
				localizer={localizer}
				messages={calendarTranslation}
				events={[
					...calendar.map(x => ({
						...x,
						start: new Date(x.start),
						end: new Date(x.end),
					})),
					{
						title: 'Moving Mission',
						allDay: true,
						start: new Date(2024, 0, 15),
						end: new Date(2024, 2, 15),
						type: 'moving_mission',
					},
				]}
				culture='nl'
				startAccessor='start'
				endAccessor='end'
				formats={calendarFormats}
				components={{
					event: CalendarDay,
					toolbar: CalendarToolbar,
					agenda: {
						time: ({ event, label }) =>
							!event.allDay
								? label
								: event.type === 'project' ||
									  event.type === 'leave'
									? `${event.extra.duration} uur`
									: label,
					},
				}}
				//selectable={true}
				//onSelectEvent={event => console.log(event)}
				//onDoubleClickEvent={event => console.log(event)}
				//onSelectSlot={slotInfo => console.log(slotInfo)}
				className='calendar'
				eventPropGetter={event => ({
					className: `calendar__event ${event.type}`,
				})}
			/>
		</div>
	);
};

export default Calendar;
