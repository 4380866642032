import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { setInviteStatus } from 'core/store/invites/invite.actions';

import Attachments from 'components/Attachment/Attachment';
import { Button } from 'components/Form';
import { Image } from 'components/Image';
import { LightBox } from 'components/LightBox/LightBox';

import { DetailSideBar } from '../DetailSideBar/DetailSideBar';

import './DetailPageLayout.scss';

export const DetailPageLayout = ({
	id,
	itemToShow,
	title,
	feedDetail,
	creator,
	createdAt,
	slug,
	days,
	organisors,
	location,
	userInvitedStatus,
	lastDay,
	maxAttendees,
	numberOfAttendees,
	type,
	showForm,
}) => {
	const [toggler, setToggler] = useState(false);
	const [currentPhoto, setCurrentPhoto] = useState(null);
	const [photoIndex, setPhotoIndex] = useState('');
	const [photoIndexNext, setPhotoIndexNext] = useState('');
	const [photoIndexPrev, setPhotoIndexPrev] = useState('');

	const today = new Date();
	const lastDate = new Date(lastDay);

	const { confirmed, invited } = useSelector(store => store.inviteReducer);
	const subscriptionDatePassed = lastDate < today;
	const allSeatsTaken =
		numberOfAttendees !== null &&
		maxAttendees !== null &&
		numberOfAttendees >= maxAttendees;

	const didMount = useRef(false);

	const prevImage = () => {
		setToggler(false);
		let i = currentPhoto - 1;
		if (currentPhoto - 1 < 0) i = itemToShow.media?.length - 1;
		setCurrentPhoto(i);
	};
	const nextImage = () => {
		setToggler(false);
		let i = currentPhoto + 1;
		if (currentPhoto + 1 >= itemToShow.media?.length) i = 0;
		setCurrentPhoto(i);
	};

	const updatePhoto = () => {
		setPhotoIndex(itemToShow.media[currentPhoto]?.filename);
		let prevIndex =
			currentPhoto == 0 ? itemToShow.media?.length - 1 : currentPhoto - 1;
		setPhotoIndexPrev(itemToShow.media[prevIndex]?.filename);
		let nextIndex =
			currentPhoto == itemToShow.media?.length - 1 ? 0 : currentPhoto + 1;
		setPhotoIndexNext(itemToShow.media[nextIndex]?.filename);
		setToggler(true);
	};

	const handleButtonClick = (e, choice) => {
		e.preventDefault();
		if (choice && showForm) {
			showForm({
				postSubmitCallback: () =>
					setInviteStatus(itemToShow.id, type, choice),
			});
		} else {
			setInviteStatus(itemToShow.id, type, choice);
		}
	};

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
			return;
		}

		updatePhoto();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPhoto]);

	return (
		<section>
			<section className='page-detail-container'>
				<section className='page-detail-container__header'>
					<h1 className='article-title'>{title}</h1>
					<section className='item-header-container'>
						<section className='item-header-container__title'>
							<h4 className='article-meta'>
								geplaatst door {creator} op{' '}
								{toShortDate(createdAt)}
							</h4>
						</section>
						<h5 className='return-to-overview'>
							<Link to={`/${slug}`}>
								<FontAwesomeIcon icon={faArrowLeftLong} />
								&nbsp;Terug naar {slug}
							</Link>
						</h5>
					</section>
				</section>
				<section className='item-detail-page'>
					<section className='item-detail-container'>
						<section className='item-body-container'>
							{feedDetail.description ? (
								<div
									className='item-content'
									dangerouslySetInnerHTML={{
										__html:
											feedDetail.description.length !== 0
												? feedDetail.description
														.replace(
															/<img .*?>/g,
															'',
														)
														.replace(
															/<[^/>][^>]*><\/[^>]+>/,
															'',
														)
												: 'Dit bericht heeft geen inhoud',
									}}
								/>
							) : (
								<div
									className='item-content'
									dangerouslySetInnerHTML={{
										__html:
											feedDetail.body.length !== 0
												? feedDetail.body
														.replace(
															/<img .*?>/g,
															'',
														)
														.replace(
															/<[^/>][^>]*><\/[^>]+>/,
															'',
														)
												: 'Dit bericht heeft geen inhoud',
									}}
								/>
							)}
						</section>
					</section>

					<section className='page-detail-container__sidebar'>
						{type !== 'news' ? (
							<section className='page-detail-container__sidebar__invites'>
								{!invited ? (
									<section className='page-detail-container__sidebar__invited'>
										<p className='not-invited'>
											Je bent niet uitgenodigd voor{' '}
											{type === 'events'
												? 'dit evenement'
												: 'deze opleiding'}
										</p>
									</section>
								) : subscriptionDatePassed ? (
									<section className='page-detail-container__sidebar__invited'>
										{confirmed === true ? (
											<p className='invited'>
												Je bent ingeschreven.
											</p>
										) : confirmed === false ? (
											<p className='invited'>
												Je bent niet ingeschreven.
											</p>
										) : (
											<p className='invited'>
												De inschrijvingsdatum is
												voorbij.
												<br />
												Je kan niet meer inschrijven
											</p>
										)}
									</section>
								) : allSeatsTaken && confirmed !== true ? (
									<section className='page-detail-container__sidebar__invited'>
										<p className='invited'>
											Het maximaal aantal inschrijvingen
											is bereikt.
											<br />
											Je kan niet meer inschrijven
										</p>
									</section>
								) : (
									<div className='page-detail-container__sidebar__buttons'>
										<Button
											buttonStyle={`${
												confirmed === false
													? 'secondary'
													: 'primary'
											}`}
											className='success'
											onClick={event => {
												handleButtonClick(event, true);
											}}>
											Aanwezig
										</Button>
										<Button
											buttonStyle={`${
												confirmed === true
													? 'secondary'
													: 'primary'
											}`}
											className='error'
											onClick={event => {
												handleButtonClick(event, false);
											}}>
											Afwezig
										</Button>
									</div>
								)}
							</section>
						) : null}
						<section>
							<DetailSideBar
								itemToShow={itemToShow}
								days={days}
								organisors={organisors}
								location={location}
								userInvitedStatus={userInvitedStatus}
								creator={creator}
								links={itemToShow.links}
								lastDay={lastDay}
								type={type}
								maxAttendees={maxAttendees}
							/>

							{itemToShow.attachments ||
							itemToShow.links ||
							invited ? (
								<Attachments
									attachments={itemToShow.attachments}
									links={itemToShow.links}
									iCal={`${type}_${id}`}
								/>
							) : null}
						</section>
					</section>
				</section>
			</section>

			<section className='lightbox'>
				{itemToShow.media.map((image, index) =>
					image.filename ? (
						<div
							key={index}
							className='image'
							onClick={() => {
								setCurrentPhoto(index);
								setToggler(true);
							}}>
							<Image
								id={image.filename}
								alt={image.description}
								rounded={false}
								shadow={false}
								showPlaceholder={false}
							/>
						</div>
					) : (
						<div />
					),
				)}

				{toggler ? (
					<LightBox
						photoIndex={photoIndex}
						photoIndexNext={
							itemToShow.media.length > 1 ? photoIndexNext : null
						}
						photoIndexPrev={
							itemToShow.media.length > 1 ? photoIndexPrev : null
						}
						prevImage={prevImage}
						nextImage={nextImage}
						setToggler={setToggler}
					/>
				) : null}
			</section>
		</section>
	);
};
